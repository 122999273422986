<template>
  <div class="context-list-cell-b">
    <div style="display: flex;justify-content: space-between;justify-items: center">
      <div>
        <span>{{ data.num }}</span>
        <span>{{ data.name }}</span>
      </div>
      <div>
        <Icon
            @click="foldHandle"
            name="arrow-up"
            class="icon-fold"
            :style="{'transform': fold ? 'rotate(180deg)' : 'rotate(0deg)'}"></Icon>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin:5px 0;" v-if="$route.path !== '/myCategory'">
      下证率 <span class="star-text">{{ data.randomNum || 0 }}%</span>
      <span class="star-group" v-for="i in new Array(5).fill(false).fill(true,0,data.sscore)">
            <img :src="star" alt="" v-if="!i">
            <img :src="starActive" alt="" v-if="i">
          </span>
    </div>

    <div class="flex" v-if="!fold">
      <div class="context-list-cell-c" v-for="_item in data.three">
        <span>{{ _item.num }} </span>
        <span>{{ _item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {Icon} from 'vant'

export default {
  name: "threeCategory",
  data() {
    return {
      fold: false,
      star: require('@/assets/star.png'),
      starActive: require('@/assets/star_active.png'),
    }
  },
  props: {
    data: {
      type: Object,
      default: () => []
    }
  },
  methods: {
    foldHandle() {
      this.fold = !this.fold;
    }
  },
  components: {Icon}
}
</script>

<style scoped>
.icon-fold {
  color: #666;
  transition: all ease .3s;
}

.context-list-cell-b {
  color: #999;
  font-size: 13px;
  margin-top: 14px;
  padding: 0 14px 0 14px;
}


.star-text {
  color: #FF5E00;
  font-size: 12px;
  margin: 0 10px;
}

.tab {
  padding: 3px 5px;
  background-color: #fff;
  color: #FF5E00;
  font-size: 12px;
}

.star-group img {
  width: 15px;
  margin: 0 1px;
}

.context-list-cell-c {
  color: #FF5E00;
  font-size: 12px;
  margin-right: 14px;
  margin-top: 10px;
  padding: 0 14px 0 0;
}
</style>